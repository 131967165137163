.fa-facebook {
  font-size: 30px;
  color: #3b5998;
}

.fa-instagram {
  font-size: 30px;
  color: #e1306c;
}

.fa-instagram:hover {
  cursor: pointer;
  color: #fd1d1d;
  transition: all .3s;
  text-decoration: none;
}

.fa-facebook:hover {
  cursor: pointer;
  color: #4A74CC;
  transition: all .3s;
  text-decoration: none;
}