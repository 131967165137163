.portfolio-section {
    font-size: 24px;
    color: black;
    letter-spacing: 3px;
    font-weight: bold;
}

.portfolio-section-mobile {
    font-size: 15px;
    color: black;
    font-weight: bold;
}

.portfolio-section:hover {
    cursor: pointer;
    color: #ec1c23;
    transition: all 0.2s ease-in-out;
    text-decoration-line: underline;
}

.dash {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px;
}

.dash-mobile {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 13px;
}
