@font-face {
  font-family: 'ubuntu';
  src: url(../fonts/ubuntu-light.ttf);
  font-style: normal;

  font-display: block;
}

* {
  font-family: ubuntu;
}

.logo{
  background-size: 'cover'; 
  height: 80px; 
  width: 170px;
}

.logo:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}


.navbar-light .navbar-nav .nav-link:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: black;
}


.navbar-expand-lg .navbar-nav .nav-mobile {
  color: black;
  text-decoration-line: none;
  text-align: center;
}

.navbar-expand-lg .navbar-nav .nav-mobile:hover {
  color: orange;
  text-decoration-line: none;
}

.nav-item-mobile{
  margin-top: 10px;
  width: 100px;
}

.nav-item-mobile:hover{
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  text-decoration-line: none;
  width: 100px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: orange;
}

.navbar-light {
  background: white
}

.navbar-light .navbar-toggler-icon {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
