html, body {
  overflow-x: hidden;
}

.image {
    width: 350px;
    height: 400px;
    object-fit: contain;
    transition: .5s ease;
    backface-visibility: hidden;
    opacity: 1;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .containers:hover .image {
    opacity: 0.3;
    cursor: pointer;
    transform: scale(0.9);
  }
  
  .containers:hover .middle {
    opacity: 1;
    cursor: pointer;
  }
  
  .logo-portfolio {
    width: 250px;
    height: 250px;
    object-fit: contain;
    padding: 16px 32px;
  }