.home-title {
    text-align: center;
    font-size: 30px;
    color: black;
}

.home-title-mobile {
    text-align: center;
    font-size: 30px;
    color: black;
}

.ui-button {
    color: white;
    background-color: purple;
}

.ui-button:hover {
    color: white;
    transition: 0.3s ease-in;
    background-color: #550D8A;
}